import { CompetitionEntryControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionEntryControllerApi'
import { CompetitionParticipantControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CompetitionParticipantControllerApi'
import { CompetitionEntryCreateCommand } from '@rallycry/api-suite-typescript/dist/models/CompetitionEntryCreateCommand'
import { some } from 'lodash-es'
import { EntityOptions } from '../useEntity'
import { useUserAccount } from '../user/useUserAccount'
import { useCompetition } from './useCompetition'
import { useCompetitionMeta } from './useCompetitionMeta'
import { useCompetitionUserView } from './useCompetitionUserView'
import {
  CompetitionTeamsRoute,
  CompetitionRoute,
  RootRoute
} from '@/core/route-keys'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useApiError } from '@/core/hooks/useApiError'

export const useCompetitionParticipant = (options?: EntityOptions) => {
  const parsedId = useParsedParam('competitionId')
  const { read: meta } = useCompetitionMeta(options)
  const competition = useCompetition(options)
  const {
    read: { mutate: mutateAll }
  } = useCompetitionUserView()
  const { ctrl } = useController(CompetitionParticipantControllerApi)
  const { ctrl: entryCtrl } = useController(CompetitionEntryControllerApi)
  const { account } = useUserAccount()
  const { handle } = useApiError()
  const { navTo } = useNavigation()

  const competitionId = Number(options?.idOrKey) || parsedId
  const participant = competition.competition?._viewInvolvement?.participantOf
  const leaderOf = competition.competition?._viewInvolvement?.leaderOf

  const createTeam = async (
    competitionId: number,
    cmd: CompetitionEntryCreateCommand,
    shouldNavigate?: boolean
  ) => {
    const res = await entryCtrl({
      skipErrorDisplay: true
    }).createCompetitionEntry({
      competitionId,
      CompetitionEntryCreateCommand: cmd
    })
    shouldNavigate &&
      navTo({
        root: RootRoute.Competition,
        rootId: competitionId,
        subRoute: CompetitionRoute.Team,
        subId: res.id,
        query: 'scrollTo=true'
      })
    await Promise.allSettled([meta.mutate(), mutateAll()])
    return res
  }

  const joinSolo = async ({
    competitionId,
    userId,
    description,
    representingOnCreate
  }: {
    competitionId: number
    userId: number
    description?: string
    representingOnCreate?: number[]
  }) => {
    const res = await ctrl({
      skipErrorDisplay: true,
      expectedStatuses: [422]
    }).saveCompetitionParticipant({
      competitionId,
      participantId: userId,
      CompetitionParticipantSaveCommand: {
        description,
        representingOnCreate
      }
    })
    navTo({
      root: RootRoute.Competition,
      rootId: competitionId,
      subRoute: CompetitionRoute.Team,
      subId: CompetitionTeamsRoute.All
    })
    await Promise.allSettled([meta.mutate(), mutateAll()])
    return res
  }

  const removeSolo = async () => {
    await ctrl().removeCompetitionParticipant({
      participantId: account?.id!,
      competitionId
    })
    Promise.allSettled([meta.mutate(), mutateAll()])
  }

  const joinWithCode = async (teamId: number, code?: string) => {
    return ctrl({ skipErrorDisplay: true }).saveCompetitionParticipant({
      competitionId,
      participantId: account?.id!,
      CompetitionParticipantSaveCommand: {
        entry: teamId,
        code
      }
    })
  }

  return {
    ...participant,
    read: meta,
    participant,
    leaderOf,
    isFullParticipant:
      (participant && !!participant?.entry?.id) ||
      some(competition.competition?._viewInvolvement?.leaderOf),
    isFreeAgentParticipant: participant && !participant?.entry?.id,
    createTeam,
    joinSolo,
    removeSolo,
    joinWithCode
  }
}
