import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import { SearchUsersRequest } from '@rallycry/api-suite-typescript/dist/apis/UserDocumentControllerApi'
import { CommunityMemberDocument } from '@rallycry/api-suite-typescript/dist/models/CommunityMemberDocument'
import React, { useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { UserDocument } from '@rallycry/api-suite-typescript/dist/models/UserDocument'
import Box from '@mui/material/Box'
import { PageItemContent } from '../page/PageItemContent'
import { UserAvatar } from '../avatar/UserAvatar'
import { RcTableResults } from '@/components/molecules/text/RcTableResults'
import { NoResults } from '@/components/molecules/text/NoResults'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { InfiniteScrollV2 } from '@/components/atoms/InfiniteScrollV2'
import { RcIcon } from '@/components/atoms/RcIcon'
import { useUserDocument } from '@/entity/user/useUserDocument'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'

export interface UserSearchProps {
  includeMe?: boolean
  placeholder?: string
  actions: (member: UserDocument) => React.ReactNode
  sideContent?: React.ReactNode
}

export const UserSearch: React.FC<UserSearchProps> = ({
  includeMe,
  placeholder,
  actions,
  sideContent
}) => {
  const [totalElements, setTotalElements] = useState(-1)
  const [filter, setFilter] = useState({
    q: '',
    includeMe
  } as SearchUsersRequest)
  const [debouncedFilter] = useDebounce(filter, 300)

  const { t } = useRcTranslation() // ph

  return (
    <>
      <PageItemContent pt={0}>
        <RcTableResults
          total={totalElements}
          align='flex-start'
          sx={{ pb: 2 }}
        />
        <Stack direction='row' spacing={2} alignItems='center'>
          <TextField
            placeholder={placeholder || t('user-search-placeholder')}
            value={filter.q}
            onChange={val => setFilter(f => ({ ...f, q: val.target.value }))}
            sx={{ flexGrow: 1 }}
            InputProps={{
              startAdornment: <RcIcon icon={['fal', 'search']} />,
              endAdornment: filter.q ? (
                <RcIconButton
                  icon={['fal', 'times']}
                  IconProps={{ size: 'xs' }}
                  onClick={() => setFilter(f => ({ ...f, q: ' ' }))}
                  style={{ cursor: 'pointer' }}
                />
              ) : null
            }}
          />
          {sideContent}
        </Stack>
      </PageItemContent>

      <Box>
        <UserSearchResults
          request={debouncedFilter}
          actions={actions}
          onTotalElementsUpdated={total => setTotalElements(total)}
        />
      </Box>
    </>
  )
}

export const UserSearchResults = ({
  request,
  onTotalElementsUpdated,
  actions
}: {
  request: any
  onTotalElementsUpdated: (total: number) => void
  actions: (member: CommunityMemberDocument) => React.ReactNode
}) => {
  const {
    users,
    totalElements,
    hasMore,
    query: { isValidating, size, setSize }
  } = useUserDocument({ request })

  useEffect(
    () => onTotalElementsUpdated(totalElements),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalElements, /* onTotalElementsUpdated */ request.q]
  )

  if (!request.q) return null

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            {users?.map(it => (
              <TableRow key={it.id}>
                <TableCell sx={{ maxWidth: { md: 300 }, overflow: 'hidden' }}>
                  <UserAvatar user={it} />
                </TableCell>
                <TableCell>
                  <Stack
                    direction='row'
                    sx={{
                      justifyContent: 'flex-end'
                    }}
                  >
                    {actions(it)}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
            {!isValidating && request.q && users?.length === 0 ? (
              <NoResults />
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
      <InfiniteScrollV2
        hasMore={hasMore}
        isLoading={isValidating}
        onLoad={() => setSize(size + 1)}
      />
    </>
  )
}
