import { BracketAssignmentResource } from '@rallycry/api-suite-typescript/dist/models/BracketAssignmentResource'
import { BracketMatchResource } from '@rallycry/api-suite-typescript/dist/models/BracketMatchResource'
import { CommunityResource } from '@rallycry/api-suite-typescript/dist/models/CommunityResource'
import { CompetitionEntryResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionEntryResource'
import { CompetitionParticipantResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionParticipantResource'
import { CompetitionResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionResource'
import { MatchState } from '@rallycry/api-suite-typescript/dist/models/MatchState'
import { useMemo } from 'react'
import {
  CompetitionBracketSettings,
  UserAccountResource,
  WinCondition
} from '@rallycry/api-suite-typescript/dist/models'
import { expand, expandById, ExpansionType } from '@/core/expand'
import { useOrganizationCommunity } from '@/entity/organization/useOrganizationCommunity'
import { useUserAccount } from '@/entity/user/useUserAccount'

export const useBracketMatchView = ({
  match,
  competition,
  participant
}: {
  match: BracketMatchResource | undefined
  competition: CompetitionResource | undefined
  participant?: CompetitionParticipantResource | null | undefined
}) => {
  const { account } = useUserAccount()
  const team1Result = useMatchResult(
    competition,
    match?.assignments?.[0]?.id,
    match,
    participant,
    account,
    0
  )

  const team2Result = useMatchResult(
    competition,
    match?.assignments?.[1]?.id,
    match,
    participant,
    account,
    1
  )

  const isMatchComplete = match?.state === MatchState.COMPLETE
  const isMatchParticipant =
    team1Result.isParticipant || team2Result.isParticipant

  const matchGames = useMemo(() => match?._expanded?.matchGame || [], [match])

  return {
    competition,
    matchGames,
    team1Result,
    team2Result,
    isMatchComplete,
    isMatchParticipant
  }
}

export const useMatchResult = (
  competition?: CompetitionResource,
  bracketAssignmentId?: number,
  match?: BracketMatchResource,
  participant?: CompetitionParticipantResource | null,
  account?: UserAccountResource | null,
  index?: 1 | 0
) => {
  const bracketAssignment = expandById<BracketAssignmentResource>(
    bracketAssignmentId,
    match?._expanded,
    ExpansionType.BracketAssignment
  )

  const team = {
    ...expand<CompetitionEntryResource>(
      bracketAssignment,
      match?._expanded,
      ExpansionType.CompetitionEntry
    ),
    _expanded: match?._expanded
  }

  const leader = expand(team, match?._expanded, ExpansionType.CompetitionLeader)

  const community = expand<CommunityResource>(
    team,
    match?._expanded,
    ExpansionType.CompetitionCommunity
  )

  const settings = expandById<CompetitionBracketSettings>(
    match?.bracket?.id,
    match?._expanded,
    ExpansionType.CompetitionBracketSettings
  )

  const orgCommunity = useOrganizationCommunity({
    community,
    expanded: match?._expanded
  })

  const displayScore =
    match?.winConditionAmount === 1 &&
    settings?.numericGameScore &&
    settings?.winCondition === WinCondition.FIRSTTO

  const score = match?._expanded?.matchGame?.reduce(
    (acc, val) =>
      val.match?.id !== match?.id
        ? acc
        : displayScore
        ? acc + (val?.scores?.[index!] || 0)
        : val.winner?.id === bracketAssignment?.id
        ? acc + 1
        : acc,
    0
  )

  const isParticipant =
    team?.id === participant?.entry?.id ||
    team.leader?.id === account?.id ||
    !!team.assistants?.some(a => a.id === account?.id)
  const isWinner = match?.winner?.id === bracketAssignment?.id
  const isMatchComplete = match?.state === MatchState.COMPLETE

  return {
    competition,
    team,
    bracketAssignment,
    score,
    orgCommunity,
    leader,
    isParticipant,
    isWinner,
    isMatchComplete
  }
}
