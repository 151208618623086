import { SourcedGameControllerApi } from '@rallycry/api-suite-typescript/dist/apis/SourcedGameControllerApi'
import { BracketAssignmentResource } from '@rallycry/api-suite-typescript/dist/models/BracketAssignmentResource'
import { CompetitionBracketSettingsResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketSettingsResource'
import { useMachine } from '@xstate/react'
import { some } from 'lodash-es'
import dynamic from 'next/dynamic'
import { useEffect } from 'react'
import { Finalized } from './Finalized'
import { MatchResultsMachine } from './match-results-machine'
import { ReportScores } from './ReportScores'
import { ReviewScores } from './ReviewScores'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useBracketMatchView } from '@/components/pages/Competition/components/useBracketMatchView'
import { invokeService } from '@/core/utils'
import { expandById, ExpansionType } from '@/core/expand'
import { RcLoader } from '@/components/atoms/RcLoader'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useMatchGameReports } from '@/entity/bracket-match/useMatchGameReports'
import { useBracketMatch } from '@/entity/bracket-match/useBracketMatch'
import { useController } from '@/core/hooks/useSWRApi'
import { useBracketMatchContactAccounts } from '@/entity/bracket-match/useBracketMatchContactAccounts'

const SourcedGames = dynamic(
  async () =>
    (await import('@/flows/Competition/MatchResultsFlow/SourcedGames'))
      .SourcedGames,
  { ssr: false, loading: () => <></> }
)

interface MatchResultsFormProps {
  bracketAssignment1?: BracketAssignmentResource
  bracketAssignment2?: BracketAssignmentResource
  matchId?: number
  competitionId?: number
  onComplete: () => void
}

export const MatchResultsFlow = ({
  matchId,
  competitionId,
  onComplete
}: MatchResultsFormProps) => {
  const { match, setWinner, reset } = useBracketMatch({ idOrKey: matchId })
  const { competition, isCompetitionModerator } = useCompetition({
    idOrKey: competitionId
  })
  const { participant } = useCompetitionParticipant({ idOrKey: competitionId })
  const { matchGames, team1Result, team2Result } = useBracketMatchView({
    match,
    competition,
    participant
  })
  const { matchGameReports, accept, adminReportGames, reportGames, decline } =
    useMatchGameReports({ idOrKey: matchId })

  const bracketSettings = expandById<CompetitionBracketSettingsResource>(
    match?.bracket?.id!,
    match?._expanded,
    ExpansionType.CompetitionBracketSettings
  )

  const externalNetwork = bracketSettings?.externalGameNetwork
  const { accounts: contactAccounts } = useBracketMatchContactAccounts({
    request: { id: match?.id }
  })
  const externalNetworkId = contactAccounts.find(
    it =>
      it.user?.id === participant?.participant?.id &&
      it.network === externalNetwork
  )?.externalId

  const teamIndex = team1Result.isParticipant ? 1 : 2

  const { ctrl } = useController(SourcedGameControllerApi)

  const isParticipant = team1Result.isParticipant || team2Result.isParticipant

  const scoreSubmissions = isCompetitionModerator
    ? matchGames?.map(it => ({
        isLocked: true,
        ordinal: it.ordinal!,
        scores: it.scores!,
        winner: it.winner?.id
      }))
    : []

  const machine = useMachine(MatchResultsMachine, {
    devTools: useFeatures().devXstate,
    context: {
      isAdmin: isCompetitionModerator,
      isBothTeams: team1Result.isParticipant && team2Result.isParticipant,
      isParticipant,
      bracketAssignment1: team1Result.bracketAssignment,
      bracketAssignment2: team2Result.bracketAssignment,
      externalNetwork,
      externalNetworkId,
      contactAccounts,
      match,
      matchGames,
      matchGameReports,
      bracketSettings,
      competition,
      participant,
      scoreSubmissions
    },
    services: {
      loadSourcedGames: async () =>
        ctrl()
          .readGamesByPlayer({
            teamIndex: teamIndex,
            network: externalNetwork,
            playerId: externalNetworkId!
          })
          .then(
            res =>
              res.content?.map(it => {
                const player = it.players?.find(
                  that => that.id === externalNetworkId
                )

                if (!player) throw Error('Unable to find playerId in result')

                return {
                  ...it,
                  teamIndex: player?.teamIndex!,
                  isWinner: it.winningTeamIndex === player?.teamIndex
                }
              })
          ),
      completed: async () => onComplete(),
      accept: async () => {
        await invokeService(() => accept())
        onComplete()
      },
      decline: async () => decline(),
      forceScores: async context => {
        await invokeService(() =>
          adminReportGames(
            context.scoreSubmissions.filter(
              it => it.winner !== -1 && !it.isLocked
            )
          )
        )
        onComplete()
      },
      submitScores: async context =>
        invokeService(() =>
          reportGames(
            context.scoreSubmissions.filter(
              it => it.winner !== -1 && !it.isLocked
            )
          )
        ),
      setWinner: async context => {
        await invokeService(() => {
          const winnerReports = context.matchGameReports
            ?.filter(it => it.winner?.id === context.assignedWinner)
            ?.map((it, idx) => ({
              ordinal: idx + 1,
              winner: it.winner?.id,
              scores: [it.score1!, it.score2!]
            }))
          return some(winnerReports)
            ? adminReportGames(winnerReports!)
            : setWinner(context.assignedWinner)
        })
        onComplete()
      },
      resetMatch: async () => {
        await invokeService(() => reset())
        onComplete()
      }
    }
  })
  const [state, send] = machine

  useEffect(() => {
    send('SET_CONTEXT', { participant, match, matchGames, matchGameReports })
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [participant, match, matchGames, matchGameReports])

  return state.matches('sourcedGames') ? (
    <SourcedGames machine={machine} />
  ) : state.matches('reportScores') ? (
    <ReportScores machine={machine} />
  ) : ['reviewScores', 'disputed'].some(state.matches) ? (
    <ReviewScores machine={machine} />
  ) : state.matches('finalized') ? (
    <Finalized machine={machine} />
  ) : (
    <RcLoader height='100%' />
  )
}
