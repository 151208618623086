import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { first } from 'lodash-es'
import { useEffect, useRef } from 'react'
import { TeamRosterSection } from './TeamRosterSection'
import { InfiniteScrollV2 } from '@/components/atoms/InfiniteScrollV2'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useQuery } from '@/core/hooks/useQuery'
import { scrollIntoViewWithOffset } from '@/core/utils'
import { useCommunityMembers } from '@/entity/community/useCommunityMembers'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import {
  TeamRank,
  useCompetitionTeamMembers
} from '@/entity/competition-team/useCompetitionTeamMembers'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useUserFamilyMembers } from '@/entity/user/useUserFamilyMembers'

export const TeamRoster = () => {
  const ref = useRef<HTMLElement>()
  const q = useQuery()
  const isScrollTo = !!q?.get('scrollTo')
  useEffect(() => {
    if (!isScrollTo) return
    scrollIntoViewWithOffset(ref.current)
  }, [ref, isScrollTo])

  const { account } = useUserAccount()
  const { settings, isSoloCompetition, isCompetitionModerator } =
    useCompetition()
  const { team } = useCompetitionTeam()
  const {
    roster,
    query: { mutate }
  } = useCompetitionTeamMembers()

  const {
    members,
    hasMore,
    query: { setSize, size, isValidating }
  } = useCommunityMembers({
    idOrKey: team?.representing?.id,
    paused:
      isSoloCompetition ||
      !team?.representing?.id ||
      !first(team._expanded?.communityProfile)?.enableDirectAddToEntry ||
      !settings?.allowCommunityManagedUsers
  })

  const { family } = useUserFamilyMembers({
    paused:
      isSoloCompetition ||
      !settings?.allowChildUsers ||
      account?.user?.id !== team?.leader?.id
  })

  useEffect(() => {
    mutate()
  }, [mutate])

  const coaches = roster.filter(
    it => it.rank === TeamRank.HeadCoach || it.rank === TeamRank.AssistantCoach
  )

  const captains = roster.filter(
    it =>
      it.rank === TeamRank.HeadCaptain || it.rank === TeamRank.AssistantCaptain
  )

  const regular = roster.filter(it => it.rank === TeamRank.Member)

  const inactive = [...members.map(it => it.member!), ...family].filter(
    it => !roster.find(r => r.id === it.id)
  )

  const canEdit =
    isCompetitionModerator ||
    !!coaches.find(it => it.id === account?.user?.id) ||
    !!captains.find(it => it.id === account?.user?.id)

  const currentUser = roster.find(it => it.id === account?.user?.id)
  const canPromote =
    isCompetitionModerator ||
    currentUser?.rank === TeamRank.HeadCaptain ||
    currentUser?.rank === TeamRank.HeadCoach

  return (
    <Stack direction='column' spacing={3}>
      <Box ref={ref} />

      <TeamRosterSection
        content={captains}
        canPromote={canPromote}
        canEdit={canEdit}
        title={
          captains.length > 1 ? (
            <RcTrans i18nKey='competition:roster.captains-title' />
          ) : (
            <RcTrans i18nKey='competition:roster.captain-title' />
          )
        }
      />
      <TeamRosterSection
        content={regular}
        canPromote={canPromote}
        canEdit={canEdit}
        title={
          regular.length > 1 ? (
            <RcTrans i18nKey='competition:roster.members-title' />
          ) : (
            <RcTrans i18nKey='competition:roster.member-title' />
          )
        }
      />
      <TeamRosterSection
        content={coaches}
        canPromote={canPromote}
        canEdit={canEdit}
        title={
          coaches.length > 1 ? (
            <RcTrans i18nKey='competition:roster.coaches-title' />
          ) : (
            <RcTrans i18nKey='competition:roster.coach-title' />
          )
        }
      />

      {canPromote && settings?.allowCommunityManagedUsers ? (
        <TeamRosterSection
          content={inactive}
          canPromote={canPromote}
          canEdit={canEdit}
          title={
            inactive.length > 1 ? (
              <RcTrans i18nKey='competition:roster.inactive-title' />
            ) : (
              <RcTrans i18nKey='competition:roster.inactive-title' />
            )
          }
        />
      ) : null}
      <InfiniteScrollV2
        hasMore={hasMore}
        isLoading={isValidating}
        onLoad={() => setSize(size + 1)}
      />
    </Stack>
  )
}
