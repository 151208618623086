import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { BracketMatchUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/BracketMatchUpdateCommand'
import { EventKind } from '@rallycry/api-suite-typescript/dist/models/EventKind'
import { Form, Formik, FormikHelpers } from 'formik'
import { Select, TextField } from 'formik-mui'
import * as Yup from 'yup'
import { BracketMatchResource } from '@rallycry/api-suite-typescript/dist/models/BracketMatchResource'
import {
  BracketKind,
  MatchState,
  WinCondition
} from '@rallycry/api-suite-typescript'
import { orderBy, groupBy, every, last, range } from 'lodash-es'
import { CompetitionEventCreateEdit } from '../schedule/CompetitionEventCreateEdit'
import { NavigationLink as Link } from '@/components/organisms/navigation/NavigationLink'
import { RcTrans } from '@/components/atoms/RcTrans'
import { MarkdownFormikField } from '@/components/molecules/input/MarkdownFormikField'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import {
  LabeledField,
  LabeledFieldHeader
} from '@/components/organisms/form/LabeledField'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'
import { useTime } from '@/core/hooks/useTime'
import { useBracketMatchesByRound } from '@/entity/bracket-match/useBracketMatchesByRound'
import { useBracket } from '@/entity/bracket/useBracket'

export const MatchEditForm = ({
  match,
  withRound,
  onSubmit
}: {
  match?: BracketMatchResource
  withRound?: boolean
  onSubmit: (
    values: BracketMatchUpdateCommand & { round?: number },
    helpers: FormikHelpers<BracketMatchUpdateCommand & { round?: number }>
  ) => Promise<any>
}) => {
  const { displayDateTime } = useTime()
  const { events } = useCompetitionEvents()
  const { bracket, bracketSettings } = useBracket({
    idOrKey: match?.bracket?.id,
    paused: !withRound
  })
  const { matches } = useBracketMatchesByRound({
    idOrKey: match?.bracket?.id,
    paused: !withRound
  })

  const sorted = orderBy(matches, it => {
    if (!!it.dateDisputed && !it.dateResolved) return 3
    if (!!it.dateConflicted && !it.dateResolved) return 2
    if (it.state !== MatchState.COMPLETE) return 1
    return 0
  })

  const getRound = (round: number) =>
    bracket?._expanded?.bracketRound?.find(it => it.number === round)

  const grouped = groupBy(sorted, it => it.round || 0)

  const allPositive = every(Object.keys(grouped), it => Number(it) >= 0)

  const filteredBracketRounds = bracket?._expanded?.bracketRound
    ?.filter(round => round.filtered === true)
    ?.sort((a, b) => (a.number! > b.number! ? 1 : -1))

  const isCustomBracket = bracketSettings?.kind === BracketKind.CUSTOM
  const maxRound = last(Object.keys(grouped)) || '0'
  const displayRounds = isCustomBracket
    ? range(1, Number(maxRound) + 2)?.map(it => it.toString())
    : Object.keys(grouped)

  const validation = Yup.object<BracketMatchUpdateCommand & { round?: number }>(
    {
      description: Yup.string(),
      winConditionAmount: Yup.number().required(),
      event: Yup.number(),
      round: withRound ? Yup.number().required() : Yup.number()
    }
  )

  const filter = [EventKind.MATCH]
  const schedule = events(filter)?.all

  return (
    <Formik
      enableReinitialize
      initialValues={{
        description: match?.description || '',
        winConditionAmount: match?.winConditionAmount || 2,
        event: match?.event?.id || ('0' as any),
        round: 1
      }}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, isSubmitting }) => (
        <Form id='matches-edit-form'>
          <Stack display='flex' direction='column' spacing={2}>
            {withRound ? (
              <LabeledField
                component={Select}
                name='round'
                type='number'
                variant='outlined'
                label={<RcTrans i18nKey='competition:bracket.round' />}
                fullWidth
              >
                {displayRounds.map(key => (
                  <MenuItem key={key} value={Number(key)}>
                    {Number(key) === 0 ? (
                      <></>
                    ) : allPositive ? (
                      <RcTrans i18nKey='competition:bracket.round' />
                    ) : Number(key) > 0 ? (
                      <RcTrans i18nKey='competition:bracket.upper-round' />
                    ) : (
                      <RcTrans i18nKey='competition:bracket.lower-round' />
                    )}
                    &nbsp;
                    {Number(key) === 0 ? '' : Math.abs(Number(key))}
                  </MenuItem>
                ))}
              </LabeledField>
            ) : null}

            <Box>
              <LabeledFieldHeader
                label={<RcTrans i18nKey='shared.description' />}
              />
              <MarkdownFormikField name='description' />
            </Box>

            <LabeledField
              component={TextField}
              name='winConditionAmount'
              type='number'
              variant='outlined'
              label={
                match?.winCondition === WinCondition.FIRSTTO ? (
                  <RcTrans i18nKey='competition:bracket.first-to-label' />
                ) : (
                  <RcTrans i18nKey='competition:bracket.best-of-label' />
                )
              }
              fullWidth
            />

            <Box>
              <LabeledField
                component={Select}
                name='event'
                variant='outlined'
                label={<RcTrans i18nKey='shared.event' />}
                fullWidth
              >
                <MenuItem value={'0'}>None</MenuItem>
                {schedule?.map(it => (
                  <MenuItem key={it.id} value={it.id}>
                    <Stack direction='column' spacing={1}>
                      <Typography>{it.name}</Typography>
                      <Typography variant='subtitle1'>
                        {displayDateTime(it.startDate)} -{' '}
                        {displayDateTime(it.endDate)}
                      </Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </LabeledField>
            </Box>

            <ModalTrigger
              activation={handleOpen => (
                <Typography variant='subtitle2'>
                  <Link onClick={handleOpen}>
                    <RcTrans i18nKey='competition:match.create-match-event' />
                  </Link>
                </Typography>
              )}
            >
              {({ handleClose }) => (
                <CompetitionEventCreateEdit
                  resource={{ kind: EventKind.MATCH }}
                  onComplete={function () {
                    handleClose()
                  }}
                />
              )}
            </ModalTrigger>
          </Stack>
          <ModalConfiguration
            title={<RcTrans i18nKey='competition:match.edit-match' />}
          >
            <RcButton
              variant='contained'
              fullWidth
              type='submit'
              disabled={isSubmitting}
              form='matches-edit-form'
            >
              <RcTrans i18nKey='shared.update' />
            </RcButton>
          </ModalConfiguration>
        </Form>
      )}
    </Formik>
  )
}
