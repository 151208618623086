import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import { ChatProvider, EscalationStatus } from '@rallycry/api-suite-typescript'
import { BracketMatchResource } from '@rallycry/api-suite-typescript/dist/models/BracketMatchResource'
import { EmbeddedCompetition } from '@rallycry/api-suite-typescript/dist/models/EmbeddedCompetition'
import { MatchState } from '@rallycry/api-suite-typescript/dist/models/MatchState'
import { find, first, some } from 'lodash-es'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { SceneKind } from '../../broadcast/useCompetitionBroadcasts'
import { MatchEditForm } from '../../shared/MatchEditForm'
import { MatchEscalationForm } from './MatchEscalationForm'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  ActionMenu,
  ActionMenuOption
} from '@/components/molecules/interactive/ActionMenu'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { Countdown } from '@/components/molecules/text/Countdown'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { useChat } from '@/components/providers/site/ChatProvider'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useApiError } from '@/core/hooks/useApiError'
import { useNavigation } from '@/core/hooks/useNavigation'
import {
  CompetitionInfoRoute,
  CompetitionRoute,
  RootRoute
} from '@/core/route-keys'
import { useBracketMatch } from '@/entity/bracket-match/useBracketMatch'
import { useBracketMatchChannels } from '@/entity/bracket-match/useBracketMatchChannels'
import { useMatchChatTicket } from '@/entity/chat/useMatchChatTicket'
import { useSocialUserContext } from '@/entity/chat/useSocialUserContext'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { MatchResultsFlow } from '@/flows/Competition/MatchResultsFlow/_MatchResultsFlow'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { useBracketMatchEscalation } from '@/entity/bracket-match/useBracketMatchEscalations'
import { borderAnimation } from '@/core/animations'
import { ConfirmingButton } from '@/components/molecules/interactive/ConfirmingButton'

export const MatchActionDisplay = ({
  canReportScores,
  competition,
  match,
  onClose,
  onReset,
  onRemove,
  onShowRoster,
  onShowRosterActive,
  smallSize
}: {
  canReportScores?: boolean
  competition?: EmbeddedCompetition
  match?: BracketMatchResource
  onClose?: () => any
  onRemove?: () => any
  onReset?: () => any
  onShowRoster?: () => any
  onShowRosterActive?: boolean
  smallSize?: boolean
}) => {
  const { user } = useUserAccount()
  const { navTo } = useNavigation()
  const { featBroadcast } = useFeatures()
  const { handle } = useApiError()
  const { isCompetitionModerator } = useCompetition({
    idOrKey: competition?.id
  })

  const { escalation, close } = useBracketMatchEscalation({
    paused: !canReportScores || !match?.escalation?.id,
    idOrKey: match?.escalation?.id
  })

  const { update } = useBracketMatch({ idOrKey: match?.id, paused: true })

  const { channels, createChats } = useBracketMatchChannels({
    idOrKey: match?.id,
    paused: !canReportScores
  })
  const rcChat = find(channels, it => it.chatProvider === ChatProvider.RALLYCRY)
  const discordChat = find(
    channels,
    it => it.chatProvider === ChatProvider.DISCORD
  )
  const discordInviteUrl = discordChat?.externalUrl

  const { handleShowChat, activeChats: dockedChats } = useChat()
  const { handleAddToChat } = useMatchChatTicket()
  const { activeChats } = useSocialUserContext()

  const isDocked = !!dockedChats?.find(it => it.id === rcChat?.externalId)
  const isInMatchChat = activeChats?.includes(rcChat?.externalId!)

  const isMatchComplete = match?.state === MatchState.COMPLETE

  const options: ActionMenuOption[] = []

  isCompetitionModerator
    ? options.push({
        key: 'edit',
        display: <RcTrans i18nKey='competition:match.edit-match' />,
        modal: ({ handleClose }) => (
          <MatchEditForm
            match={match}
            onSubmit={async (values, helpers) => {
              try {
                await update(match?.id!, {
                  ...values,

                  // pass null if attempting to clear event
                  event: values.event! > 0 ? values.event : (null as any)
                })
                handleClose()
              } catch (error) {
                await handle(error, { values, helpers })
              }

              return Promise.resolve()
            }}
          />
        )
      })
    : null

  isCompetitionModerator &&
    !isInMatchChat &&
    rcChat &&
    options.push({
      key: 'join-chat',
      display: <RcTrans i18nKey='competition:match.join-match-chat' />,
      action: async () => {
        await handleAddToChat(rcChat)
        if (!!onShowRoster) {
          // onShowRoster proxy for knowing if on list view
          handleShowChat(rcChat.externalId!)
        }
      }
    })

  isCompetitionModerator &&
    options.push({
      key: 'recreate-chat',
      display: <RcTrans i18nKey='competition:match.recreate-match-chat' />,
      action: async () => {
        createChats(match?.bracket?.id!, { id: [match?.id!] })
      }
    })

  isInMatchChat &&
    !isDocked &&
    options.push({
      key: 'dock-chat',
      display: <RcTrans i18nKey='shared.dock-chat' />,
      action: async () => {
        handleShowChat(rcChat?.externalId!)
      }
    })

  canReportScores || isMatchComplete
    ? options.push({
        key: 'results',
        display: canReportScores ? (
          <RcTrans i18nKey='competition:match.report-scores' />
        ) : (
          <RcTrans i18nKey='competition:match.results' />
        ),
        modal: ({ handleClose }) => (
          <MatchResultsFlow
            matchId={match?.id}
            competitionId={competition?.id}
            onComplete={() => {
              onClose?.()
              handleClose()
            }}
          />
        )
      })
    : null

  canReportScores &&
    discordInviteUrl &&
    options.push({
      key: 1,
      action: async () => window.open(discordInviteUrl),
      display: <RcTrans i18nKey='competition:match.chat' />
    })

  onShowRoster &&
    options.push({
      key: 'view',
      display: <RcTrans i18nKey='competition:match.view-match' />,
      action: async () =>
        navTo({
          root: RootRoute.Competition,
          rootId: competition?.id,
          subRoute: CompetitionRoute.Match,
          subId: match?.id
        })
    })

  featBroadcast &&
    !!user &&
    options.push({
      key: 'broadcast',
      display: <RcTrans i18nKey='competition:match.broadcast-match-display' />,
      action: async () => {
        navTo({
          root: RootRoute.Competition,
          rootId: competition?.id,
          subRoute: CompetitionRoute.Info,
          subId: CompetitionInfoRoute.Broadcast,
          query: `kind=${SceneKind.MatchRoster}&bracket=${match?.bracket?.id}&match=${match?.id}&scrollTo=true`
        })
      }
    })

  isCompetitionModerator &&
    onRemove &&
    options.push({
      key: 'delete',
      display: <RcTrans i18nKey='competition:match.delete-match-display' />,
      action: onRemove,
      confirmation: (
        <RcTrans i18nKey='competition:match.delete-match-confirmation' />
      )
    })

  isCompetitionModerator &&
    onReset &&
    options.push({
      key: 'reset',
      display: <RcTrans i18nKey='competition:match.reset-match-display' />,
      action: onReset,
      confirmation: (
        <RcTrans i18nKey='competition:match.reset-match-confirmation' />
      )
    })

  const scoreReportPending =
    match?.entryReportCompleted?.filter(it => !!it).length === 1

  const rosterToggle = onShowRoster ? (
    <Button variant='text' size='small' onClick={() => onShowRoster()}>
      <RcTrans i18nKey='competition:team.roster-label' />
      <RcIcon
        ml={2}
        icon={['fal', onShowRosterActive ? 'chevron-up' : 'chevron-down']}
      />
    </Button>
  ) : null

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent={
          onShowRoster && !smallSize ? 'space-between' : 'flex-end'
        }
        px={2}
      >
        {rosterToggle && !smallSize ? rosterToggle : null}
        <Stack
          display='flex'
          direction='row'
          justifyContent='flex-end'
          spacing={smallSize ? 2 : 4}
          p={2}
          alignItems='center'
        >
          {canReportScores ? (
            <ModalTrigger
              activation={handleOpen => (
                <Button
                  onClick={handleOpen}
                  color='primary'
                  variant={scoreReportPending ? 'outlined' : 'contained'}
                  size='small'
                >
                  {scoreReportPending ? (
                    <Countdown
                      target={match?.negotiableUntil!}
                      onComplete={() => onClose?.()}
                    />
                  ) : (
                    <RcTrans i18nKey='competition:match.report-scores' />
                  )}
                </Button>
              )}
            >
              {({ handleClose }) => (
                <MatchResultsFlow
                  matchId={match?.id}
                  competitionId={competition?.id}
                  onComplete={() => handleClose()}
                />
              )}
            </ModalTrigger>
          ) : null}
          {canReportScores ? (
            <ModalTrigger
              activation={handleOpen =>
                match?.escalation?.id ? (
                  <Tooltip
                    title={
                      <Stack direction='column' spacing={2} maxWidth={300}>
                        <Typography variant='subtitle2' color='text.secondary'>
                          <RcTrans
                            i18nKey={`competition:EscalationReason.${escalation?.reason}`}
                          />{' '}
                          - {escalation?._expanded?.user?.[0]?.id}
                        </Typography>
                        <Typography variant='body2'>
                          {escalation?.description}
                        </Typography>
                      </Stack>
                    }
                  >
                    <Box
                      position={'relative'}
                      p={0.5}
                      width='100%'
                      height='100%'
                    >
                      <Box
                        sx={theme => ({
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          p: 0.5,
                          borderRadius: 15,
                          ...borderAnimation(theme.palette.warning.main)
                        })}
                      />
                      <ConfirmingButton
                        message='Do you want to un-flag this match?'
                        onClick={() => close(escalation?.id!)}
                        size='small'
                        variant='contained'
                        color='warning'
                        icon={smallSize ? ['fas', 'hand'] : undefined}
                      >
                        <RcTrans i18nKey='competition:match.admin-flagged' />
                      </ConfirmingButton>
                    </Box>
                  </Tooltip>
                ) : smallSize ? (
                  <Tooltip
                    title={<RcTrans i18nKey='competition:match.flag-admin' />}
                  >
                    <RcIconButton
                      onClick={handleOpen}
                      size='small'
                      icon={['fas', 'hand']}
                    />
                  </Tooltip>
                ) : (
                  <RcButton
                    onClick={handleOpen}
                    size='small'
                    variant='outlined'
                  >
                    <RcTrans i18nKey='competition:match.flag-admin' />
                  </RcButton>
                )
              }
            >
              {({ handleClose }) => (
                <MatchEscalationForm onComplete={() => handleClose()} />
              )}
            </ModalTrigger>
          ) : null}
          {isInMatchChat && !isDocked ? (
            <Tooltip title={<RcTrans i18nKey='shared.dock-chat' />}>
              <RcIconButton
                icon={['fal', 'messages']}
                onClick={() => handleShowChat(rcChat?.externalId!)}
              />
            </Tooltip>
          ) : null}
          {canReportScores && discordInviteUrl ? (
            <Tooltip title={<RcTrans i18nKey='competition:match.chat' />}>
              <RcIconButton
                icon={['fab', 'discord']}
                onClick={async () => window.open(discordInviteUrl)}
              />
            </Tooltip>
          ) : null}
          <ActionMenu options={options} endicon={false} />
        </Stack>
      </Stack>
      {rosterToggle && smallSize ? rosterToggle : null}
    </>
  )
}
